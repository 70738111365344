// grid
$column: 20px;
$gutter: 12px;
$grid-columns: 12;
$max-width: 1200px;

// font stacks
$playfairDisplay: 'Playfair Display', Georgia, 'Times New Roman', serif;
$gothamBook: "GothamBook", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$gothamLight: "GothamLight", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$gothamThin: "GothamThin", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$helvetica: "Helvetica Neue", "Helvetica","Arial", sans-serif;
$times: "Times", "Times New Roman", serif;

// breakpoints
$viewport960: 960px;
$viewport767: 767px;
$viewport640: 640px;
$viewport590: 590px;
$viewport500: 500px;
$viewport350: 350px;

$bp960: 'screen and (max-width: 960px)';
$bp767: 'screen and (max-width: 767px)'; //for use with topnav
$bp640: 'screen and (max-width: 640px)';
$bp590: 'screen and (max-width: 590px)'; // for use with search filters
$bp500: 'screen and (max-width: 500px)';
$bp350: 'screen and (max-width: 350px)'; // for use with search filters


// Colors
$white: #fff;
$black: #000;
$grey: #767676;
$blue: #4c63b8;

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$color-heather-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Animations
$base-duration: 150ms;
$base-timing: ease-in-out;

// margins
$main-left-margin: 5%;