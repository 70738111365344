ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;

  &%default-ul {
    list-style-type: disc;
    margin-bottom: $small-spacing;
    padding-left: $base-spacing;
  }

  &%default-ol {
    list-style-type: decimal;
    margin-bottom: $small-spacing;
    padding-left: $base-spacing;
  }
}

dl {
  margin-bottom: $small-spacing;

  dt {
    font-weight: bold;
    margin-top: $small-spacing;
  }

  dd {
    margin: 0;
  }
}

//bullets
%li-bullet {
  position: relative;
  padding-left: rem(12);

  &:before {
    content: '\2022';
    position: absolute;
    left: 0;
  }

}
