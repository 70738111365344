@font-face {
    font-family: 'GothamBook';
    src: url('../fonts/Gotham-Book.eot');
    src: url('../fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Gotham-Book.ttf') format('truetype'),
         url('../fonts/Gotham-Book.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GothamThin';
    src: url('../fonts/Gotham-Thin.eot');
    src: url('../fonts/Gotham-Thin.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Gotham-Thin.ttf') format('truetype'),
         url('../fonts/Gotham-Thin.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GothamLight';
    src: url('../fonts/Gotham-Light.eot');
    src: url('../fonts/Gotham-Light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Gotham-Light.ttf') format('truetype'),
         url('../fonts/Gotham-Light.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MyriadProLight';
    src: url('../fonts/myriadpro-light-webfont.eot');
    src: url('../fonts/myriadpro-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/myriadpro-light-webfont.woff') format('woff'),
         url('../fonts/myriadpro-light-webfont.ttf') format('truetype'),
         url('../fonts/myriadpro-light-webfont.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MyriadProSemibold';
    src: url('../fonts/myriadpro-semibold-webfont.eot');
    src: url('../fonts/myriadpro-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/myriadpro-semibold-webfont.woff') format('woff'),
         url('../fonts/myriadpro-semibold-webfont.ttf') format('truetype'),
         url('../fonts/myriadpro-semibold-webfont.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}